<template>
  <div class="realtor">
    <a-row :gutter="8">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="经销商名称">
              <a-input v-model="searchData.cusName" placeholder="请输入经销商名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="商品名称">
              <a-input v-model="searchData.productName" placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="searchData.flowStatus">
                <a-select-option value="1">未处理</a-select-option>
                <a-select-option value="2">待审核</a-select-option>
                <a-select-option value="3">审核通过</a-select-option>
                <a-select-option value="4">已拒绝</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isCusPriceAdd">新增</a-button>
        <a-button type="primary" v-if="selectedRowKeys.length == 1 && isCusPriceEdit" @click="handleEdit" icon="edit">编辑</a-button>
        <a-button type="primary" v-if="selectedRowKeys.length == 1 && isCusPriceDel" @click="toHandler('del')" icon="delete">删除
        </a-button>
        <a-button type="default"
                  v-if="statusList == true && (selectedRowKeys.length == 0 || (selectedRowKeys.length > 0 && item[0].flowStatus == 1)) && isCusPriceSubmit"
                  @click="onSubmitAudit(1)" icon="form">提交审核
        </a-button>
        <a-button type="default" v-if="(selectedRowKeys.length == 0 || (selectedRowKeys.length == 1 && item[0].flowStatus == 2)) && isCusPriceExamined"
                  @click="onAudit" icon="form">审核
        </a-button>
        <a-button type="default" @click="downItemExcel" icon="download" v-if="isCusPriceExport">导出</a-button>
        <a-upload
          name="file"
          :action="uploadUrl"
          :headers="headers"
          :showUploadList="false"
          @change="uploadChange"
        >
          <a-button icon="upload" type="default" v-if="isCusPriceImport">导入</a-button>
        </a-upload>
      </div>
      <a-col :span="5">
        <div style="border:1px solid #e8e8e8; background:white; height: 472px; overflow-y: auto">
          <a-tree :treeData="dataList"
            v-if="dataList.length>0"
            :expanded-keys="expandedKeys"
            @expand="onExpand"
            @select="handleSelect"
          >
          </a-tree>
        </div>
      </a-col>
      <a-col :span="19">
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          :pagination="page"
          @change="changeTable"
        >
          <span slot="productName" class="click-style" slot-scope="text, row" style="cursor: pointer;">
            <div @click="onTagList(row, 'product')">{{ text }}</div>
          </span>
          <span slot="name" slot-scope="text">
            <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
          </span>
          <span slot="flowStatus" slot-scope="item">
            <!-- {{item | status}} -->
            <a-tag v-if="item == 1" color="#e6a23c">未处理</a-tag>
            <a-tag v-if="item == 2" color="#409eff">待审核</a-tag>
            <a-tag v-if="item == 3" color="#67c23a">审核通过</a-tag>
            <a-tag v-if="item == 4" color="#f5222d">已拒绝</a-tag>
          </span>
          <span slot="cusName" slot-scope="text, row" class="click-style" style="cursor: pointer">
            <div @click="onTagList(row, 'info')">{{ row.cusName }}</div>
          </span>
          <span slot="productImage" slot-scope="item">
            <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
          </span>
          <span slot="productPrice" slot-scope="item">{{ item.toFixed(2) }}</span>
          <span slot="action" slot-scope="text, row">
            <a @click="handleEdit(row)">编辑</a>
            <a-divider type="vertical"/>
            <a @click="delByIds([row.id])">删除</a>
          </span>
        </a-table>
      </a-col>
    </a-row>

    <!-- 编辑表单 -->
    <CustomerInfo-edit-modal ref="CustomerInfoEditModal" @reload="getData"></CustomerInfo-edit-modal>
    <EditForm ref="EditForm" @reload="getData"></EditForm>
    <CustomerPricePolicy-edit-modal ref="CustomerPricePolicyEditModal" @reload="getData" ></CustomerPricePolicy-edit-modal>
    <ProductGoods-edit-modal ref="ProductGoodsEditModal" @reload="getData"></ProductGoods-edit-modal>
    <a-modal title="审核" width="30%" :visible="visible" :confirm-loading="confirmLoading" :footer="null" :maskClosable="false">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="原因">
          <a-textarea v-model="rejectReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="handleCancel">取消</a-button>
        <a-button type="danger" @click="onSubmitAudit(3)">拒绝</a-button>
        <a-button type="primary" @click="onSubmitAudit(2)">通过</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import {columns} from './components/colums.js'
  import CustomerInfoEditModal from './components/cusdetail.vue'
  import CustomerPricePolicyEditModal from './components/CustomerPricePolicyEditModal.vue'
  import EditForm from './components/EditForm'
  import {
    delCustomerPricePolicy,
    listCustomerPricePolicy,
    selectByIdCustomerPricePolicy,
  } from './api/CustomerPricePolicyApi'
  import {STable, SearchTree} from '@/components'
  import Vue from 'vue'
  import {ACCESS_TOKEN} from '@/store/mutation-types'
  import {checkPermission} from '@/utils/permissions'
  import ProductGoodsEditModal from '@/views/product_goods/components/ProductGoodsEditModal.vue'

  const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  const qs = require('qs')
  export default {
    name: 'customerPricePolicy',
    components: {
      CustomerPricePolicyEditModal,
      EditForm,
      STable,
      SearchTree,
      CustomerInfoEditModal,
      ProductGoodsEditModal,
    },
    data() {
      return {
        selectedKeys: [],
        autoExpandParent: true,
        visible: false,
        columns: columns,
        confirmLoading: false,
        tableData: [],
        rejectReason: '',
        expandedKeys: [0],
        dataList: [],
        statusList: [],
        holderText: '搜索经销商',
        searchData: {}, // 搜索条件
        tableLoading: false, //表格loading
        page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
        selectedRowKeys: [],
        selectedRows: [],
        isCusPriceAdd: checkPermission("cus:price:add"),
        isCusPriceEdit: checkPermission("cus:price:edit"),
        isCusPriceDel: checkPermission("cus:price:del"),
        isCusPriceExport: checkPermission("cus:price:export"),
        isCusPriceImport: checkPermission("cus:price:import"),
        isCusPriceExamined: checkPermission("cus:price:examined"),
        isCusPriceSubmit: checkPermission("cus:price:submit"),
        url: 'https://lk.pg115.com',
        // url: 'http://am.cncdt.com.cn:31000',
        //上传的数据
        uploadData: [],
        customerId: 0,
        //文件数据
        fileList: [],
        //上传文件地址
        uploadUrl: 'https://lk.pg115.com/api/customer/customer/customerPricePolicy/excelReader',
        // uploadUrl: 'http://am.cncdt.com.cn:31000/api/customer/customer/customerPricePolicy/excelReader',
        //上传文件请求头
        headers: {
          'Authorization-Admin': this.$store.getters.token,
        },
        //addEnable: checkPermission('system:oss:add'),
      }
    },
    filters: {
      status(type) {
        const typeMap = {
          1: '未审核',
          2: '待审核',
          3: '审核通过',
          4: '已拒绝',
        }
        return typeMap[type]
      },
    },
    computed: {
      rowSelection() {
        const _this = this
        return {
          fixed: true,
          type: 'radio', //"radio"
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            _this.selectedRowKeys = selectedRowKeys
            _this.selectedRows = selectedRows
          },
        }
      },
      hasSelected() {
        return this.selectedRowKeys.length > 0
      },
    },
    watch: {
      checkedKeys(val) {
      },
    },
    methods: {
      onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
        this.autoExpandParent = false;
      },
      onSelect(selectedKeys, info) {
        this.selectedKeys = selectedKeys;
      },
      uploadChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList)
        }
      },
      handleCancel(e) {
        this.visible = false;
      },
      downItemExcel() {
        // 导出
        let rowId = Number(this.selectedRowKeys.toString())
        const postData = Object.assign({}, this.searchData, {
          year: this.searchData.yearMonth && this.searchData.yearMonth.year(),
          month: this.searchData.yearMonth && this.searchData.yearMonth.month() + 1,
          'Authorization-Admin': this.$store.getters.token,
        })
        if (this.selectedRowKeys.length > 1) {
          this.$notification.warning({message: '导出不能多选'})
          return
        }
        if (!rowId) {
          window.location.href = `${this.url}/api/customer/customer/customerPricePolicy/excelExport?${qs.stringify(
            postData
          )}`
        } else {
          window.location.href = `${this.url}/api/customer/customer/customerPricePolicy/excelExport?id=` + rowId
        }
      },
      start() {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.selectedRowKeys = []
        }, 1000)
      },
      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.item = selectedRows
        let arr = selectedRows.map((e) => {
          return e.flowStatus
        })
        this.statusList = arr.every((el) => el == arr[0])
      },
      onAudit() {
        let _this=this;
        if (_this.item.length === 0) {
          _this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }
        _this.visible = true;
      },
      // 审核
      onSubmitAudit(value) {
        let list = []
        if (this.item.length === 0) {
          this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }
        if (value == 1) {
          this.item.filter((e) => {
            list.push({
              id: e.id,
              flowStatus: 2,
              rejectReason: this.rejectReason
            })
          })
        } else if (value == 2) {
          this.item.filter((e) => {
            list.push({
              id: e.id,
              flowStatus: 3,
              rejectReason: this.rejectReason
            })
          })
        } else if (value == 3) {
          this.item.filter((e) => {
            list.push({
              id: e.id,
              flowStatus: 4,
              rejectReason: this.rejectReason
            })
          })
        }
        this.axios.post('/api/customer/customer/customerPricePolicy/submitReview', list).then((res) => {
          if (res.code == 200) {
            this.rejectReason = ''
            this.$notification.success({message: res.message})
            this.visible = false
            this.getData(this.customerId)
          } else {
            this.$notification.error({message: res.message})
            this.getData(this.customerId)
          }
        })
      },

      handleSelect(value) {
        let id = Number(value.toString())
        this.customerId = id
        this.getData(id)
      },
      /**
       * 获取表格数据
       */
      getData(id) {
        this.selectedRows = []
        this.selectedRowKeys = []
        this.tableLoading = true
        // this.url = window.location.host
        console.log('url------', this.url)
        listCustomerPricePolicy({
          pageNumber: this.page.current,
          pageSize: this.page.pageSize,
          cusId: id == 0 ? '' : id,
          ...this.searchData,
        })
          .then((res) => {
            if (res.code === 200) {
              const {total, records} = res.body
              this.page.total = total
              this.tableData = records
            }
          })
          .finally(() => (this.tableLoading = false))
      },
      /**
       * 筛选查询
       */
      getDataFilter() {
        this.page.current = 1;
      this.page.pageSize = 10
        this.getData()
      },
      /**
       * 重置
       */
      reset() {
        this.searchData = {}
        this.page = Object.assign({}, this.page)
        this.getData()
        this.getAllCustomer()
      },
      /**
       * 更改分页
       */
      changeTable(pagination) {
        this.page = pagination
        this.getData(this.customerId)
      },
      /**
       * 点击行选中
       */
      changeTableRow(record) {
        return {
          on: {
            click: (e) => {
              console.log(e)
              if (this.item[0] === record.id) {
                this.selectedRowKeys = []
                this.selectedRows = []
              } else {
                this.selectedRowKeys = [record.id]
                this.selectedRows = [record]
              }
            },
          },
        }
      },
      // 修改是否启动
      onStatusChange(status, row) {
        const that = this
        let num = !status
        that.axios.post('/api/product/product/baseProperty/editFlagEnable/' + row.id, {flagEnable: num}).then((res) => {
          that.$notification.success({message: res.message})
        })
      },
      // 编辑
      handleEdit() {
        const _this = this
        if (_this.selectedRowKeys.length > 1) {
          _this.$notification.warning({message: '编辑不能多选'})
          return
        }
        if (_this.selectedRowKeys.length === 0) {
          _this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }
        _this.$refs.EditForm.isShow(_this.item[0], 'edit')
      },
      // 删除
      delByIds(id) {
        const _this = this
        if (_this.selectedRows.length === 0) {
          _this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }

        _this.$confirm({
          title: '删除警告',
          content: '确认要删除此条记录吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            const res = await delCustomerPricePolicy(id)
            if (res.code === 200) {
              _this.$notification.success({message: res.message})
              _this.getData()
            } else {
              _this.$notification.error({message: res.message})
            }
          },
          onCancel() {
          },
        })
      },
      onTagList(item, name) {
        const _this = this
        if (name === 'product') {
          _this.$refs.ProductGoodsEditModal.setRowData(item, 'cusCheck')
        }
        if (name === 'info') {
          _this.$refs.CustomerInfoEditModal.setRowData(item, 'check')
        }
      },
      toHandler(name) {
        const _this = this
        if (name === 'add') {
          _this.$refs.CustomerPricePolicyEditModal.setRowData({}, 'add')
          return
        }
        if (_this.selectedRowKeys.length === 0) {
          _this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }
        switch (name) {
          case 'edit':
            if (_this.selectedRowKeys.length > 1) {
              _this.$notification.warning({message: '编辑不能多选'})
              return
            }
            _this.$refs.CustomerPricePolicyEditModal.setRowData(_this.item[0], 'edit')
            break
          case 'del':
            _this.$confirm({
              title: '删除警告',
              content: '确认要删除此条记录吗?',
              okText: '确认',
              cancelText: '取消',
              async onOk() {
                const res = await delCustomerPricePolicy(_this.item[0].id)
                if (res.code === 200) {
                  _this.$notification.success({message: res.message})
                  _this.getData()
                } else {
                  _this.$notification.error({message: res.message})
                }
              },
              onCancel() {
              },
            })
            break
        }
      },
      getAllCustomer() {
        this.axios.get('/api/customer/customer/customerInfo/treeAllList?status=true').then((res) => {
          this.dataList = res.body
          // this.dataList = data.map((m) => {
          //   let name = m.name + '（' + m.cusCode + '）'
          //   return {
          //     key: m.id,
          //     title: name,
          //   }
          // })
        })
      }
    },
    created() {
      this.getAllCustomer()
      this.getData()
    },
  }
</script>

<style lang="less" scoped>
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
  }

  .click-style:hover {
    color: #0033FF
  }

  .footer-bts {
    text-align: right;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #E9E9E9;
  }
</style>
